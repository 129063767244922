export default (to, from, next) => {
  if (
    localStorage.getItem('userInfo') != null &&
    localStorage.getItem('userInfo').length > 0
  ) {
    next()
   
    //next()
  } else {
    localStorage.clear()
   // window.reload();
    next('/app/sessions/sign-in-two')
  }
}
