/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";
import menuItem from '@/serviceApi/menu_slide'

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {

        path: "/",
        component: () => import("@/views/app/Index"),
        beforeEnter: authenticate,
        redirect: "dashbord",
        //redirect: "/app/sessions/sign-in-two",
        meta: {
          requiresAuth: true //menuItem.reponse_menu('Dashbord')
        },

      children: [

        {
          path: "meta/backoffice/all-dashbord",
          component: () => import("@/views/app/pages/dashbord/dashbord"),
          meta: {
            requiresAuth: true//menuItem.reponse_menu('Dashbord')
          },
        },
        {
          path: "dashbord",
          component: () => import("@/views/app/pages/dashbord/dashbord"),
          meta: {
            requiresAuth: true//menuItem.reponse_menu('Dashbord')
          },
        },
        {
          path: "/app/finances",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: "dashbord-finances",
              component: () => import("@/views/app/pages/finance/dashbord-finance"),
              meta: {
                requiresAuth: true//menuItem.reponse_menu('Dashbord')
              },
            },
          ],
        },

        {
          path: "clients",
          component: () => import("@/views/app/pages/client/gestion_client"),
          meta: {
            requiresAuth:  menuItem.reponse_menu('Clients')
          },
        },
        {
          path: "client-kyc",
          component: () => import("@/views/app/pages/client/gestion_client_kyc"),
          meta: {
            requiresAuth:  menuItem.reponse_menu('KYC-client')
          },
        },
        {
          name: "client-kyc-profil",
          path: "/client-kyc-profil/:id/:key",
          component: () => import("@/views/app/pages/client/Profile_client"),
          props: true,
          meta: {
            requiresAuth:  menuItem.reponse_menu('KYC-client')
          },

        },
        {
          path: "/app/operations",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: "transactions",
              component: () => import("@/views/app/pages/operation/transaction"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Operations/Transactions')
              },
            },
            {
              path: "transactions-all",
              component: () => import("@/views/app/pages/operation/transactions"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Operations/Transactions')
              },
            },
            {
              path: "transactions-pm",
              component: () => import("@/views/app/pages/Dollars_pages/pm_historique"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Operations/Transactions')
              },
            },
            {
              path: "crypto-deposits",
              component: () => import("@/views/app/pages/operation/crypto_deposits"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Operations/Transactions')
              },
            },
            {
              path: "tarification",
              component: () => import("@/views/app/pages/operation/tarifs"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Operations/Transactions')
              },
            },
            {
              path: "commissions-client",
              component: () => import("@/views/app/pages/operation/transaction_commission"),
              meta: {
                requiresAuth:   menuItem.reponse_menu('Operations/commision-client')
              },
            },
          ]
        },
        {
          path: "/app/serveurs",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: "blacklist",
              component: () => import("@/views/app/pages/serveur/blacklist")
            },
            {
              path: "sms",
              component: () => import("@/views/app/pages/serveur/sms_serveur"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Serveur')
              },
            },
            {
              path: "sms/:numero",
              component: () => import("@/views/app/pages/serveur/sms_serveur"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Serveur')
              },
            },
            {
              path: "monnaies",
              component: () => import("@/views/app/pages/serveur/monnaies"),
              meta: {
                requiresAuth:   menuItem.reponse_menu('Serveur')
              },
            },
          ]
        },
        {
          path: "/app/parametre",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: "compte-admins",
              component: () => import("@/views/app/pages/account/compte_admins"),
              meta: {
                requiresAuth:  menuItem.reponse_menu('Parametre')
              },
            },
            {
              path: "monnaies",
              component: () => import("@/views/app/pages/finance/monnaies"),
              meta: {
                requiresAuth:   menuItem.reponse_menu('Parametre')
              },
            },
            {
              path: "partenaires",
              component: () => import("@/views/app/pages/account/gestion_partenaire"),
              meta: {
                requiresAuth:   menuItem.reponse_menu('Parametre')
              },
            },
            {
              path: "partenaires-monnaie",
              component: () => import("@/views/app/pages/finance/partenaire_monnaie"),
              meta: {
                requiresAuth:  true //menuItem.reponse_menu('Parametre')
              },
            },

            {
              path: "droits-access/:id",
              component: () => import("@/views/app/pages/account/gestion_fenetre_acces"),
              props: true,
              meta: {
                requiresAuth: true //menuItem.reponse_menu('KYC-client')
              },
            },

          ]

        },
        {
          path: "/app/pages",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: "blank",
              component: () => import("@/views/app/pages/Blank")
            }
          ]
        }
      ]
    },
    {
      path: "/app/sessions",
      component: () => import("@/views/app/sessions/Sessions"),

      children: [
        {
          path: "sign-in",
          component: () => import("@/views/app/sessions/SignIn")
        },
        {
          path: "error",
          component: () => import("@/views/app/sessions/Error"),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: "forgot",
          component: () => import("@/views/app/sessions/Forgot")
        },
        {
          path: "lockscreen",
          component: () => import("@/views/app/sessions/Lockscreen")
        },
        {
          path: "sign-in-two",
          name: "login",
          component: () => import("@/views/app/sessions/SignInTwo"),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: "sign-in-three",
          component: () => import("@/views/app/sessions/SignInThree"),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: "sign-in-four",
          component: () => import("@/views/app/sessions/SignInFour")
        },
        {
          path: "sign-in-five",
          component: () => import("@/views/app/sessions/SignInFive")
        },
        {
          path: "sign-up",
          component: () => import("@/views/app/sessions/SignUp")
        },
        {
          path: "sign-up-2",
          name: "signup",
          component: () => import("@/views/app/sessions/SignUpTwo")
        },
        {
          path: "sign-up-5",
          component: () => import("@/views/app/sessions/SignUpFive")
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  if(to.meta.requiresAuth==true){
    next();
  }if(to.meta.requiresAuth==false){
    next();
  }

});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
