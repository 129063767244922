/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

import VueApexCharts from 'vue-apexcharts';
// mock
import "./fake-db/index.js";
import CKEditor from 'ckeditor4-vue';
//import 'vue-image-zoomer/dist/style.css';!
import imageZoom from 'vue-image-zoomer';

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import ProductZoomer from 'vue-product-zoomer'

import VueCryptojs from 'vue-cryptojs'
import logoutPlugin from 'vue-auto-logout'

const logoutOptions = {
  stagnateTime: 30 * 60 * 1000,
  //stagnateTime: 30 * 60,
  detectTime: 30 * 60
}



Vue.use(logoutPlugin, logoutOptions)

Vue.use(VueCryptojs)

Vue.use(ProductZoomer)

Vue.use(VueSweetalert2);

import VueImg from 'v-img';

Vue.use(VueImg);

Vue.component('apexchart', VueApexCharts);
Vue.use( CKEditor );
Vue.use(octaviaKit);
Vue.config.productionTip = false;
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

new Vue({
  store,
  router,
  vuetify,
  components:{
    imageZoom
  },
  render: h => h(App)
}).$mount("#app");
