
const reponse_menu_principale = {
  

      reponse_menu (item) {
        //const list=localStorage.getItem("menu");
        const list= JSON.parse(localStorage.getItem("menu"));
       // console.log(list)

       if(list==null){
           return false;
        }
        
        const result = list.filter(lists => lists.droit_fenetre.libelle_fenetre== item );

        /*for (let i = 0; i < list.length; i++) {
            const element = list[i];
            console.log(element);
           
            
        }*/

       // const result = list.find(lists => lists.droit_fenetre.libelle_fenetre== item );
        
        //console.log(result);
        if(result.length>0){
            console.log('true')
            return true;
         
        }else{
            return false;
        }
       
    
    },
    
}

export default reponse_menu_principale;
